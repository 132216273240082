<template>
   <div>
      <component v-if="onLine" v-bind:is="layout"></component>
      <h6 v-else>Jste offline, zapněte si prosím internet.</h6>
   </div>
</template>

<script>
   // layouts
   import appLayout from "./layouts/app-layout.vue";
   import authLayout from "./layouts/auth-layout.vue";
   import "@/assets/sass/app.scss";

   export default {
      components: {
         app: appLayout,
         auth: authLayout
      },
      data() {
         return {
            onLine: navigator.onLine,
            showBackOnline: false
         };
      },
      computed: {
         layout() {
            return this.$store.getters.layout;
         }
      },
      watch: {
         "$route"(to) {
            document.title = to.meta + " | Adminpanel" || "Adminpanel";
         },
         onLine(v) {
            if (v) {
               this.showBackOnline = true;
               setTimeout(() => {
                  this.showBackOnline = false;
               }, 1000);
            }
         }
      },
      methods: {
         updateOnlineStatus(e) {
            const {
               type
            } = e;
            this.onLine = type === "online";
         }
      },
      mounted() {
         window.addEventListener("online", this.updateOnlineStatus);
         window.addEventListener("offline", this.updateOnlineStatus);
      },
      beforeDestroy() {
         window.removeEventListener("online", this.updateOnlineStatus);
         window.removeEventListener("offline", this.updateOnlineStatus);
      }
   };
</script>

<style scoped>
   h6 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2em;
      width: 100%;
      text-align: center;
      padding: 0 1rem;
   }
</style>
