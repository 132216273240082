<template>
    <header>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <div class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
<!--                    <portal-target name="back-button" class="back-button">-->
<!--                        <div class="dropdown b-dropdown nav-item hamburger btn-group">-->
<!--                            <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">-->
<!--                                <svg-->
<!--                                    xmlns="http://www.w3.org/2000/svg"-->
<!--                                    width="24"-->
<!--                                    height="24"-->
<!--                                    viewBox="0 0 24 24"-->
<!--                                    fill="none"-->
<!--                                    stroke="currentColor"-->
<!--                                    stroke-width="2"-->
<!--                                    stroke-linecap="round"-->
<!--                                    stroke-linejoin="round"-->
<!--                                    class="feather feather-menu"-->
<!--                                >-->
<!--                                    <line x1="3" y1="12" x2="21" y2="12"></line>-->
<!--                                    <line x1="3" y1="6" x2="21" y2="6"></line>-->
<!--                                    <line x1="3" y1="18" x2="21" y2="18"></line>-->
<!--                                </svg>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </portal-target>-->
                    <li class="nav-item theme-logo">
                        <router-link to="/objednavky">
                            <img src="@/assets/img/navbar/logo.png" class="navbar-logo" alt="logo" />
                        </router-link>
                    </li>
                </ul>
                <div class="d-none horizontal-menu">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>
<!--                <ul class="navbar-item flex-row ml-md-0 ml-auto">-->
<!--                    <li class="nav-item align-self-center search-animated" :class="{ 'show-search': $store.state.is_show_search }">-->
<!--                        <svg-->
<!--                            @click="$store.commit('toggleSearch', !$store.state.is_show_search)"-->
<!--                            xmlns="http://www.w3.org/2000/svg"-->
<!--                            width="24"-->
<!--                            height="24"-->
<!--                            viewBox="0 0 24 24"-->
<!--                            fill="none"-->
<!--                            stroke="currentColor"-->
<!--                            stroke-width="2"-->
<!--                            stroke-linecap="round"-->
<!--                            stroke-linejoin="round"-->
<!--                            class="feather feather-search toggle-search"-->
<!--                        >-->
<!--                            <circle cx="11" cy="11" r="8"></circle>-->
<!--                            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>-->
<!--                        </svg>-->
<!--                        <form class="form-inline search-full form-inline search" :class="{ 'input-focused': $store.state.is_show_search }">-->
<!--                            <div class="search-bar">-->
<!--                                <input type="text" class="form-control search-form-control ml-lg-auto" placeholder="Search..." />-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </li>-->
<!--                </ul>-->

                <div class="navbar-item flex-row ml-auto align-items-center">
                    <div class="dark-mode d-flex align-items-center">
                        <a v-if="$store.state.dark_mode == 'light'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('dark')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-sun"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </svg>
                            <span class="ml-2">Světlý</span>
                        </a>
                        <a v-if="$store.state.dark_mode == 'dark'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('system')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-moon"
                            >
                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                            </svg>
                            <span class="ml-2">Tmavý</span>
                        </a>
                        <a v-if="$store.state.dark_mode == 'system'" href="javascript:;" class="d-flex align-items-center" @click="toggleMode('light')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                <polygon points="12 15 17 21 7 21 12 15"></polygon>
                            </svg>
                            <span class="ml-2">Systém</span>
                        </a>
                    </div>

<!--                    <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="nav-link" class="nav-item language-dropdown">-->
<!--                        <template #button-content>-->
<!--                            <img v-if="selectedLang" :src="require(`@/assets/images/flags/${selectedLang.code}.png`)" class="flag-width" alt="flag" />-->
<!--                        </template>-->
<!--                        <perfect-scrollbar>-->
<!--                            <b-dropdown-item-->
<!--                                v-for="item in countryList"-->
<!--                                :key="item.code"-->
<!--                                link-class="d-flex align-items-center"-->
<!--                                :active="$i18n.locale === item.code"-->
<!--                                @click.prevent="changeLanguage(item)"-->
<!--                            >-->
<!--                                <img :src="require(`@/assets/images/flags/${item.code}.png`)" class="flag-width" alt="" /> <span>{{ item.name }}</span>-->
<!--                            </b-dropdown-item>-->
<!--                        </perfect-scrollbar>-->
<!--                    </b-dropdown>-->

<!--                    <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="nav-link" class="nav-item message-dropdown" :right="true">-->
<!--                        <template #button-content>-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="24"-->
<!--                                height="24"-->
<!--                                viewBox="0 0 24 24"-->
<!--                                fill="none"-->
<!--                                stroke="currentColor"-->
<!--                                stroke-width="2"-->
<!--                                stroke-linecap="round"-->
<!--                                stroke-linejoin="round"-->
<!--                                class="feather feather-mail"-->
<!--                            >-->
<!--                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>-->
<!--                                <polyline points="22,6 12,13 2,6"></polyline>-->
<!--                            </svg>-->
<!--                        </template>-->

<!--                        <b-dropdown-item>-->
<!--                            <b-media class="media">-->
<!--                                <template #aside>-->
<!--                                    <div class="avatar avatar-xl">-->
<!--                                        <span class="avatar-title rounded-circle">KY</span>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <h5 class="usr-name">Kara Young</h5>-->
<!--                                <p class="msg-title">ACCOUNT UPDATE</p>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-item>-->
<!--                            <b-media class="media">-->
<!--                                <template #aside>-->
<!--                                    <img src="@/assets/images/profile-15.jpeg" alt="avatar" />-->
<!--                                </template>-->
<!--                                <h5 class="usr-name">Daisy Anderson</h5>-->
<!--                                <p class="msg-title">ACCOUNT UPDATE</p>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-item>-->
<!--                            <b-media class="media">-->
<!--                                <template #aside>-->
<!--                                    <div class="avatar avatar-xl">-->
<!--                                        <span class="avatar-title rounded-circle">OG</span>-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <h5 class="usr-name">Oscar Garner</h5>-->
<!--                                <p class="msg-title">ACCOUNT UPDATE</p>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                    </b-dropdown>-->

<!--                    <div class="dropdown b-dropdown nav-item message-dropdown btn-group">-->
<!--                        <a @click.prevent="toogleFullscreen" href="#" class="nav-link">-->
<!--                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minimize" data-v-5522efca=""><path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path></svg>-->
<!--                        </a>-->
<!--                    </div>-->

<!--                    <b-dropdown  toggle-tag="a" variant="icon-only" toggle-class="nav-link" menu-class="notification-scroll" class="nav-item notification-dropdown" :right="true">-->
<!--                        <template #button-content>-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="24"-->
<!--                                height="24"-->
<!--                                viewBox="0 0 24 24"-->
<!--                                fill="none"-->
<!--                                stroke="currentColor"-->
<!--                                stroke-width="2"-->
<!--                                stroke-linecap="round"-->
<!--                                stroke-linejoin="round"-->
<!--                                class="feather feather-bell"-->
<!--                            >-->
<!--                                <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>-->
<!--                                <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>-->
<!--                            </svg>-->
<!--                            <span class="badge badge-success"></span>-->
<!--                        </template>-->

<!--                        <b-dropdown-item>-->
<!--                            <b-media class="server-log">-->
<!--                                <template #aside>-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-server"-->
<!--                                    >-->
<!--                                        <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>-->
<!--                                        <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>-->
<!--                                        <line x1="6" y1="6" x2="6" y2="6"></line>-->
<!--                                        <line x1="6" y1="18" x2="6" y2="18"></line>-->
<!--                                    </svg>-->
<!--                                </template>-->
<!--                                <div class="data-info">-->
<!--                                    <h6 class="">Server Rebooted</h6>-->
<!--                                    <p class="">45 min ago</p>-->
<!--                                </div>-->

<!--                                <div class="icon-status">-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-x"-->
<!--                                    >-->
<!--                                        <line x1="18" y1="6" x2="6" y2="18"></line>-->
<!--                                        <line x1="6" y1="6" x2="18" y2="18"></line>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item>-->
<!--                            <b-media>-->
<!--                                <template #aside>-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-heart"-->
<!--                                    >-->
<!--                                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>-->
<!--                                    </svg>-->
<!--                                </template>-->
<!--                                <div class="data-info">-->
<!--                                    <h6 class="">Licence Expiring Soon</h6>-->
<!--                                    <p class="">8 hrs ago</p>-->
<!--                                </div>-->

<!--                                <div class="icon-status">-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-x"-->
<!--                                    >-->
<!--                                        <line x1="18" y1="6" x2="6" y2="18"></line>-->
<!--                                        <line x1="6" y1="6" x2="18" y2="18"></line>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-divider></b-dropdown-divider>-->
<!--                        <b-dropdown-item>-->
<!--                            <b-media class="file-upload">-->
<!--                                <template #aside>-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-file-text"-->
<!--                                    >-->
<!--                                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>-->
<!--                                        <polyline points="14 2 14 8 20 8"></polyline>-->
<!--                                        <line x1="16" y1="13" x2="8" y2="13"></line>-->
<!--                                        <line x1="16" y1="17" x2="8" y2="17"></line>-->
<!--                                        <polyline points="10 9 9 9 8 9"></polyline>-->
<!--                                    </svg>-->
<!--                                </template>-->
<!--                                <div class="data-info">-->
<!--                                    <h6 class="">Kelly Portfolio.pdf</h6>-->
<!--                                    <p class="">670 kb</p>-->
<!--                                </div>-->

<!--                                <div class="icon-status">-->
<!--                                    <svg-->
<!--                                        xmlns="http://www.w3.org/2000/svg"-->
<!--                                        width="24"-->
<!--                                        height="24"-->
<!--                                        viewBox="0 0 24 24"-->
<!--                                        fill="none"-->
<!--                                        stroke="currentColor"-->
<!--                                        stroke-width="2"-->
<!--                                        stroke-linecap="round"-->
<!--                                        stroke-linejoin="round"-->
<!--                                        class="feather feather-check"-->
<!--                                    >-->
<!--                                        <polyline points="20 6 9 17 4 12"></polyline>-->
<!--                                    </svg>-->
<!--                                </div>-->
<!--                            </b-media>-->
<!--                        </b-dropdown-item>-->
<!--                    </b-dropdown>-->
<!--                    <h6 class="nav-item" v-if="user">{{ user.name }}</h6>-->
                    <b-dropdown toggle-tag="a" variant="icon-only" toggle-class="user nav-link" class="nav-item user-profile-dropdown" :right="true" v-if="user">
                        <template #button-content>
                                <img v-if="user.pouzivatel.images" :src="user.pouzivatel.images.original_url" alt="avatar" />
                                <svg v-if="!user.pouzivatel.images" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder" data-v-5522efca=""><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        </template>

<!--                        <b-dropdown-item to="/users/profile">-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="24"-->
<!--                                height="24"-->
<!--                                viewBox="0 0 24 24"-->
<!--                                fill="none"-->
<!--                                stroke="currentColor"-->
<!--                                stroke-width="2"-->
<!--                                stroke-linecap="round"-->
<!--                                stroke-linejoin="round"-->
<!--                                class="feather feather-user"-->
<!--                            >-->
<!--                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>-->
<!--                                <circle cx="12" cy="7" r="4"></circle>-->
<!--                            </svg>-->
<!--                            Profile-->
<!--                        </b-dropdown-item>-->
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user placeholder" data-v-5522efca=""><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                            {{ user.pouzivatel.name }}
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="logout">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                           Odhlásit se
                        </b-dropdown-item>
                    </b-dropdown>

                    <div class="dropdown b-dropdown nav-item hamburger btn-group">
                        <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-menu"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
<!--        <div class="sub-header-container">-->
<!--            <header class="header navbar navbar-expand-sm">-->
<!--                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">-->
<!--                    <svg-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                        width="24"-->
<!--                        height="24"-->
<!--                        viewBox="0 0 24 24"-->
<!--                        fill="none"-->
<!--                        stroke="currentColor"-->
<!--                        stroke-width="2"-->
<!--                        stroke-linecap="round"-->
<!--                        stroke-linejoin="round"-->
<!--                        class="feather feather-menu"-->
<!--                    >-->
<!--                        <line x1="3" y1="12" x2="21" y2="12"></line>-->
<!--                        <line x1="3" y1="6" x2="21" y2="6"></line>-->
<!--                        <line x1="3" y1="18" x2="21" y2="18"></line>-->
<!--                    </svg>-->
<!--                </a>-->

<!--                &lt;!&ndash; Portal vue for Breadcrumb &ndash;&gt;-->
<!--                <portal-target name="breadcrumb"></portal-target>-->


<!--            </header>-->
<!--        </div>-->
        <!--  END NAVBAR  -->

    </header>
</template>
<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        data() {
            return {
                selectedLang: null,
                countryList: this.$store.state.countryList,
            };
        },
        computed: {
          ...mapGetters("auth", ["user"])
        },
        mounted() {
            this.selectedLang = this.$appSetting.toggleLanguage();

            this.toggleMode();

        },
        created() {
          if (localStorage.getItem("authToken")) {
            this.getUserData();
          }
        },

        methods: {
          ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),

            toggleMode(mode) {
                this.$appSetting.toggleMode(mode);
            },

            changeLanguage(item) {
                this.selectedLang = item;
                this.$appSetting.toggleLanguage(item);
            },

            logout() {
              this.sendLogoutRequest()
            },
            toogleFullscreen() {
                if (!document.fullscreenElement) {
                    document.documentElement.requestFullscreen();
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                }
            }
        }
    };
</script>
