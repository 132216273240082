<template>
   <transition name="fade-loader">
      <div v-if="loading" class="overlay-loader" :class="!$store.state.is_show_sidebar ? 'loader-full' : ''">
         <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
         <div v-if="timerFinished" class="timer-loader">
            <h6>Nastala chyba? <span @click="$router.go()">Znovu načíst stránku</span></h6>
            <p>Pokud problém přetrvává, chvíli počkejte nebo kontaktujte.</p>
         </div>
         <div class="upload-percentage" v-if="uploadPercentage !== null">{{ uploadPercentage }}%</div>
      </div>
   </transition>

</template>

<script>
   import { mapState } from "vuex";


   export default {
      name: "loader",
      computed: {
         ...mapState("loader", ["loading", "uploadPercentage"])
      },
      watch: {
         loading(newValue) {
            if (newValue) {
               this.startTimer();
            } else {
               this.stopTimer();
            }
         }
      },
      data() {
         return {
            timer: null,
            timerFinished: false,
         };
      },
      methods: {
         startTimer() {
            this.timer = setTimeout(() => {
               this.timerFinished = true;
            }, 20000);
         },
         stopTimer() {
            clearTimeout(this.timer);
            this.timerFinished = false;
         }
      },
      beforeDestroy() {
         this.stopTimer();
      }
   };
</script>

<style scoped lang="scss">

   .upload-percentage {
      position: absolute;
      font-size: 0.9rem;
      font-weight: bold;
      margin-top: 3rem;
   }


   .timer-loader {
      text-align: center;
      padding: 0 1.5rem;

      span {
         text-decoration: underline;
         font-weight: bold;

         &:hover {
            text-decoration: none;
         }
      }
   }


   .fade-loader-enter-active,
   .fade-loader-leave-active {
      transition: opacity 0.5s ease;
   }


   .fade-loader-enter-from,
   .fade-loader-leave-to {
      opacity: 0;
   }


   .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      margin-left: 0.3rem;
   }


   .lds-ellipsis div {
      position: absolute;
      top: 33px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #41c2f7;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
   }


   .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
   }


   .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
   }


   .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
   }


   .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
   }


   @keyframes lds-ellipsis1 {
      0% {
         transform: scale(0);
      }
      100% {
         transform: scale(1);
      }
   }


   @keyframes lds-ellipsis3 {
      0% {
         transform: scale(1);
      }
      100% {
         transform: scale(0);
      }
   }


   @keyframes lds-ellipsis2 {
      0% {
         transform: translate(0, 0);
      }
      100% {
         transform: translate(24px, 0);
      }
   }


   .overlay-loader {
      //position: absolute;
      //width: 100%;
      //height: 100%;
      position: fixed;
      width: calc(100vw - 70px);
      height: calc(100vh - 56px);
      background: #f1f2f3de;
      backdrop-filter: blur(10px);
      z-index: 1020;
      transition: all 0.5s ease-in-out;
      touch-action: pan-y;
      user-select: none;
      -webkit-user-drag: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media all and (max-width: 991px) {
         position: fixed;
         width: 100%;
      }

      @media all and (max-width: 575px) {
         height: calc(100vh - 58px);
      }
   }


   .loader-full {
      width: 100%;
   }


   .dark .overlay-loader {
      background: rgb(0 0 0 / 80%);
   }

</style>
